  .header {
    height: 56px;
    background-color: var(--mantine-color-gray-3);
    border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }

  .headerContainer {
    margin-inline: 0;
    padding-left: 10px;
  }

  .burger {
    background-color: var(--mantine-color-black);
  }
  
  .inner {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .link {
    display: block;
    line-height: 1;
    padding: 8px 12px;
    border-radius: var(--mantine-radius-sm);
    text-decoration: none;
    font-size: var(--mantine-font-size-sm);
    background-color: var(--mantine-color-gray-3);
    color: var(--mantine-color-black);
    font-weight: 500;
  
    [data-mantine-color-scheme] &[data-active] {
      background-color: var(--mantine-color-yellow-filled);
      color: var(--mantine-color-black);
    }
  }

  .link:hover {
    background-color: var(--mantine-color-gray-5);
    color: var(--mantine-color-black);
  }

  .sideNavLink {
    text-decoration: none;
    text-align: center;
    padding: 12px 12px;
    background-color: var(--mantine-color-gray-3);
    color: var(--mantine-color-black);
  }

  .sideNavLink:hover {
    background-color: var(--mantine-color-yellow-filled);
  }

  .sideNavGroup {
    .sideNavLink:first-child {
      border-radius: 4px 4px 0 0;
    }

    .sideNavLink:last-child {
      border-radius: 0 0 4px 4px;
    }
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 50%;
    height: 50px;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--mantine-color-gray-3);
    color: var(--mantine-color-black);
    font-style: italic;

    .footerGroupLeft {
      margin-right: auto;
    }

    .footerGroupRight {
      justify-content: right;
    }
  }

  .logo {
    height: 100%;
    padding-left: 0;
  }

  .logoText {
    color: var(--mantine-color-black);
    font-weight: 700;
    font-style: italic;
    font-size: 55px;
    padding: 0 10px;
    
  @media all and (min-width: 1024px) {
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
  }
  
  @media all and (min-width: 480px) and (max-width: 768px) {
    font-size: 45px;
  }
  
  @media all and (max-width: 480px) { 
    font-size: 40px;
  }

    .logoFirst {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--mantine-color-yellow-filled);
    }

    .logoSecond {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--mantine-color-gray-6);
    }
  }